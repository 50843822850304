import { Link } from 'gatsby'
import React from 'react'
import { buildImageObj, cn, format, toPlainText } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import PortableText from './portableText'
import simpleSvgPlaceholder from '@cloudfour/simple-svg-placeholder'
import { responsiveTitle3, smallCustom, small, microCustom } from './typography.module.css'
import * as styles from './blog-post-preview.module.css'
import ClockIcon from './icon/ClockIcon'

function BlogPostPreview(props) {
  const placeholderImgDefaults = {
    bgColor: '#e7ebed',
    textColor: 'lightgray',
    text: props.title
      ?.split?.(/[\s-_,]/)
      .slice?.(-3)
      .join?.(' ')
      .replace?.(/[()\\/"']/g, ''),
    fontSize: '125%',
  }
  const smallText = props.small
  const imgPlaceHolderData = simpleSvgPlaceholder(placeholderImgDefaults)
  const { category = {} } = props
  return (
    // console.log({ props }) || (
    <div
      data-size={smallText ? 'small' : 'normal'}
      className={props.isInList ? styles.inList : styles.inGrid}
    >
      <div className={styles.thumbWrapper}>
        <div className={styles.leadMediaThumb}>
          <Link
            className={styles.textNoDecoration}
            to={`/${category.name}/${props.slug?.current || ''}`}
          >
            {props.mainImage && props.mainImage.asset ? (
              <img
                loading="lazy"
                src={imageUrlFor(buildImageObj(props.mainImage))
                  .width(600)
                  .height(Math.floor((9 / 16) * 600))
                  .auto('format')
                  .url()}
                alt={props.mainImage.alt}
              />
            ) : (
              // <SanityImage {...props.mainImage} width={280} alt='som' />
              <img src={imgPlaceHolderData} alt={props.title} />
            )}
          </Link>
        </div>
      </div>
      <div>
        <Link
          className={styles.textNoDecoration}
          to={`/${category.name}/${props.slug?.current || ''}`}
        >
          <div className={styles.text}>
            <h3 className={cn(smallText ? smallCustom : responsiveTitle3, styles.title)}>
              {props.title}
            </h3>
            {!smallText && props._rawExcerpt && props.showExcerpt === true && (
              <div className={styles.excerpt}>
                {toPlainText(props._rawExcerpt).substring(0, 180)}
              </div>
            )}
          </div>
        </Link>
        <div className={cn(smallText ? microCustom : small, styles.date)}>
          <ClockIcon />
          <time dateTime={new Date(props.publishedAt).toLocaleDateString('en-US')}>
            {new Date(props.publishedAt).toLocaleDateString('ar-SA', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          </time>
          {props.isInList ? (
            // todo: remove hard coded 'anshth-w-faalyat'
            <span className={styles.inlineTags}>
              <Link
                to={`/${category.name === 'activities' ? 'anshth-w-faalyat' : category.name}`}
              >
                {category.name_ar}
              </Link>
            </span>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default BlogPostPreview
