import React from 'react'
import { Link } from 'gatsby'
import { GrDocumentExcel, GrDocumentPdf } from 'react-icons/gr'
import * as styles from './calendarUI.module.css'
import { cn } from '../../lib/helpers'
import { small, base, large } from './../typography.module.css'

const text_labels = {
  en: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
}
const download_item_header = {
  num: 'م',
  year: 'السنة',
  pdfLink: 'PDF',
  excelLink: 'XLS',
  description: 'التقويم',
}
const download_items = [
  {
    excelLink: '/files/calendars/1446.xls',
    pdfLink: '/files/calendars/1446.pdf',
    year: '1446',
    download: 'تقويم الجمعية لمنطقة القطيف - عام ١٤٤٦ هـ',
    description: 'تقويم الجمعية لمنطقة القطيف وما جاورها للعام 1446هـ',
  },
  {
    excelLink: '/files/calendars/1445.xls',
    pdfLink: '/files/calendars/1445.pdf',
    year: '1445',
    download: 'تقويم الجمعية لمنطقة القطيف - عام ١٤٤٥ هـ',
    description: 'تقويم الجمعية لمنطقة القطيف وما جاورها للعام 1445هـ',
  },
  {
    excelLink: '/files/calendars/1444.xls',
    pdfLink: '/files/calendars/1444.pdf',
    year: '1444',
    download: 'تقويم الجمعية لمنطقة القطيف - عام ١٤٤٤ هـ',
    description: 'تقويم الجمعية لمنطقة القطيف وما جاورها للعام 1444هـ',
  },
  {
    excelLink: '/files/calendars/1443.xls',
    pdfLink: '/files/calendars/1443.pdf',
    year: '1443',
    download: 'تقويم الجمعية لمنطقة القطيف - عام ١٤٤٣',
    description: 'تقويم الجمعية لمنطقة القطيف وما جاورها للعام 1443هـ',
  },
  {
    excelLink: '/files/calendars/1442.xls',
    pdfLink: '/files/calendars/1442.pdf',
    year: '1442',
    download: 'تقويم الجمعية لمنطقة القطيف - عام ١٤٤٢',
    description: 'تقويم الجمعية لمنطقة القطيف وما جاورها للعام 1442هـ',
  },
  {
    excelLink: '/files/calendars/1441.xls',
    pdfLink: '/files/calendars/1441.pdf',
    year: '1441',
    description: 'تقويم الجمعية لمنطقة القطيف وما جاورها للعام 1441هـ',
  },
  {
    excelLink: '/files/calendars/1440.xls',
    pdfLink: '/files/calendars/1440.pdf',
    year: '1440',
    description: 'تقويم الجمعية لمنطقة القطيف وما جاورها للعام 1440هـ',
  },
]
const CalendarUI = props => {
  const { hijriView, hijriDay, month_ar, daysInMonth } = props
  const { hideDownload = false } = props
  return (
    <div className={hideDownload ? '' : styles.wrapper}>
      <div className={styles.calendar}>
        {hideDownload ? (
          <Link to="/calendar">
            {' '}
            <h3>{month_ar} 1446 هـ</h3>
          </Link>
        ) : (
          <h3>{month_ar} 1446 هـ</h3>
        )}
        <div className={styles.labels}>
          {text_labels.en.map(dayLabel => (
            <span key={dayLabel}>{dayLabel}</span>
          ))}
        </div>
        <div className={styles.dates}>
          {hijriView.map(week => {
            return week.map((day, ind) => {
              if (day && day <= daysInMonth) {
                return (
                  <span key={ind} className={hijriDay === day ? styles.dayCurrent : null}>
                    {day}
                  </span>
                )
              }
              return <span key={ind} />
            })
          })}
        </div>
      </div>
      {hideDownload ? null : (
        <div className={styles.calendar}>
          <h3>تحميل تقاويم الجمعية كملف Excel أو PDF</h3>
          <div className={styles.download}>
            <div className={cn(base, styles.downloadHeader)}>
              {Object.values(download_item_header).map(cellText => (
                <div>{cellText}</div>
              ))}
            </div>
            {download_items.map(({ year, excelLink, pdfLink, description, download }, ind) => (
              <div className={cn(styles.downloadRow, small)}>
                <div>{ind + 1}</div>
                <div>{year}</div>
                <div className={large}>
                  <a
                    download={download || `تقويم الجمعية لمنطقة القطيف - عام ${year}`}
                    href={pdfLink}
                  >
                    <GrDocumentPdf />
                  </a>
                </div>
                <div className={large}>
                  <a
                    href={excelLink}
                    download={download || `تقويم الجمعية لمنطقة القطيف - عام ${year}`}
                  >
                    <GrDocumentExcel />
                  </a>
                </div>
                <div>{description}</div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
export default CalendarUI
