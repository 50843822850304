import React from 'react'
import { useForm } from 'react-hook-form'
import * as styles from './siteLaunchVote.module.css'

const Vote = props => {
  const {
    register,
    handleSubmit,
    formState: { error },
  } = useForm()
  const [state, setState] = React.useState(false)
  const [apiHasResponsed, setApiHasResponsed] = React.useState(false)
  const [formSubmitted, setFormSubmitted] = React.useState(false)
  const [vote, setVote] = React.useState('')
  const [noVoteSelectedError, setNoVoteSelectedError] = React.useState(null)
  const onSubmit = async formData => {
    if (!formData.vote)
      return setNoVoteSelectedError('الرجاء اختيار احدى الخيارات قبل ارسال التقييم')
    setFormSubmitted(true)
    const alertText = JSON.stringify(formData, null, 2)
    setVote(formData.vote)
    const ok = await fetch('/.netlify/functions/siteLaunchVote', {
      method: 'POST',
      body: JSON.stringify(formData),
    })
      .then(async r => {
        if (!r.ok) return { status: 'error', error: await r.json() }
        return r.json()
      })
      .catch(e => console.log({ e, vote }))
    const { status, error } = await ok
    if (status === 'Ok') {
      setState(true)
      setApiHasResponsed(true)
    } else {
      console.log(error.errors)
      setApiHasResponsed(true)
    }
  }

  //because preact incompitable with react-hook-form for checkbox and radio
  const customRegister = (name, options) => {
    const { ref, ...field } = register(name, options)

    const customRef = (...args) => {
      setTimeout(() => ref(...args))
    }

    return { ...field, ref: customRef }
  }
  return (
    <div className={styles.container}>
      {formSubmitted && apiHasResponsed && state ? null : (
        <>
          <h4 style={{ outline: noVoteSelectedError ? 'tomato solid 1px' : 'none' }}>
            كيف تقيم الموقع الجديد..؟
          </h4>
          <form onSubmit={handleSubmit(onSubmit)}>
            <fieldset disabled={formSubmitted && !apiHasResponsed}>
              <div className={styles.radioGroup}>
                <div className={styles.fieldItemRadio}>
                  <input
                    onFocus={() => setNoVoteSelectedError(null)}
                    {...customRegister('vote')}
                    id="vExcellent"
                    type="radio"
                    value="ممتاز"
                  />
                  <label htmlFor="vExcellent">ممتاز </label>
                </div>
                <div className={styles.fieldItemRadio}>
                  <input
                    onFocus={() => setNoVoteSelectedError(null)}
                    {...customRegister('vote')}
                    id="vVeryGood"
                    type="radio"
                    value="جيد جداً"
                  />
                  <label htmlFor="vVeryGood">جيد جداً</label>
                </div>
                <div className={styles.fieldItemRadio}>
                  <input
                    onFocus={() => setNoVoteSelectedError(null)}
                    {...customRegister('vote')}
                    id="vGood"
                    type="radio"
                    value="جيد"
                  />
                  <label htmlFor="vGood">جيد </label>
                </div>{' '}
                <div className={styles.fieldItemRadio}>
                  <input
                    onFocus={() => setNoVoteSelectedError(null)}
                    {...customRegister('vote')}
                    id="vBad"
                    type="radio"
                    value="سيئ"
                  />
                  <label htmlFor="vBad">سيئ </label>
                </div>
              </div>
              <div className={styles.smallStack}>
                <h4> هل لديك اقتراحات تتعلق بموقع الجمعية الجديد؟</h4>
                <div className={styles.fieldItem}>
                  <input
                    {...customRegister('voteText')}
                    type="text"
                    placeholder="إذا كان لديك إقتراحات ضعها هنا.."
                  />
                </div>
              </div>
              <button type="submit">تقييم</button>
              {noVoteSelectedError === null ? null : (
                <small style={{ color: 'tomato' }}>{noVoteSelectedError}</small>
              )}
            </fieldset>
          </form>
        </>
      )}
      {state && <div role="alert">شكرا على مشاركتك في التقييم</div>}
      {!state && apiHasResponsed && <div role="alert">خطأ</div>}
    </div>
  )
}
export default Vote
