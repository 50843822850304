import React from 'react'
import * as styles from './main-content.module.css'

function MainContent(props) {
  return <div className={styles.main}>{props.children}</div>
}

function NewsGrid(props) {
  return <div className={styles.newsGrid}>{props.children}</div>
}

function VideosGrid(props) {
  return <div className={styles.videosGrid}>{props.children}</div>
}

MainContent.NewsGrid = NewsGrid
MainContent.VideosGrid = VideosGrid

export default MainContent
