import React from 'react'
import WeatherUI from './weather-ui'
import * as styles from './weather-ui.module.css'
import { useInView } from 'react-intersection-observer'

const kelvinToCelsius = kelvinTmp => kelvinTmp - 273.15

function parseWeatherData(weatherData) {
  if (!(weatherData && weatherData.main)) return {}
  const { temp, temp_max, temp_min } = weatherData && weatherData.main
  const { main, description, icon } = weatherData && weatherData.weather[0]
  return {
    weatherMin: kelvinToCelsius(temp_min),
    weatherMax: kelvinToCelsius(temp_max),
    weatherCurr: kelvinToCelsius(temp),
    main,
    description,
    icon,
  }
}

export default props => {
  const [weatherData, setWeater] = React.useState([])
  const [error, setError] = React.useState(false)
  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  })

  async function fetchWeather() {
    return await fetch('/.netlify/functions/openweather')
      .then(re => {
        // console.log(re.status)
        if (re.status > 300) {
          setError(true)
          throw new Error('unable to fetch weather data')
        }
        return re.json()
      })
      .then(re => {
        setWeater(re)
      })
      .catch(e => {
        setError(true)
        console.log('error:', e)
      })
  }

  const weatherProps = parseWeatherData(weatherData)

  React.useEffect(() => {
    // console.log({ inView })
    if (inView) fetchWeather()
  }, [inView])

  return (
    <div ref={ref} className={styles.fixedWrapper}>
      {error && inView ? (
        <WeatherUI {...weatherProps} error={error} />
      ) : (
        <WeatherUI {...weatherProps} />
      )}
    </div>
  )
}
