import { Link, useStaticQuery, graphql } from 'gatsby'
import React from 'react'
import { cn } from '../lib/helpers'
import { GatsbyImage } from 'gatsby-plugin-image'
import simpleSvgPlaceholder from '@cloudfour/simple-svg-placeholder'
import { mapEdgesToNodes } from '../lib/helpers'
import * as styles from './adItem.module.css'

const AdItem = props => {
  const adItemData = useStaticQuery(graphql`
    query AdData {
      allSanityAdsItem {
        edges {
          node {
            _id
            adsDetail {
              url
              slug {
                current
              }
              adsImage {
                asset {
                  gatsbyImageData
                  altText
                  url
                }
              }
            }
          }
        }
      }
    }
  `)

  const adItems = (adItemData || {}).allSanityAdsItem
    ? mapEdgesToNodes(adItemData.allSanityAdsItem)
    : []

  // if (!Array.isArray(adge) || (Array.isArray(adge) && edge.length === 0)) return null
  //todo 1. filter based on valid period of the ad.
  //todo 2. display not only first one (in case multipe ads are valid)
  const adsImages = adItems
    .filter(({ _id }) => !_id.startsWith('draft'))
    .map(({ adsDetail }) => adsDetail.adsImage)

  return (
    <>
      <div style={{ display: 'grid' }}>
        {adsImages.map(adsImage => {
          const gatsbyImageData = adsImage.asset.gatsbyImageData
          return <GatsbyImage image={gatsbyImageData} />
        })}
      </div>
    </>
  )

  // return (
  //   <InitiallyHiddenShort>
  //     {adsImages.map(adsImage => (
  //       <Img style={{ flex: '1' }} fluid={adsImage} />
  //       // <div style={{ flex: '1' }}>.</div>
  //     ))}
  //   </InitiallyHiddenShort>
  // )
}

const InitiallyHiddenShort = ({ children }) => {
  const [show, setShow] = React.useState(false)

  const placeholderImgDefaults = {
    bgColor: '#e7ebed',
    textColor: 'lightgray',
    text: 'مساحة اعلانية',
    fontSize: '225%',
  }
  const imgPlaceHolderData = simpleSvgPlaceholder(placeholderImgDefaults)
  React.useEffect(() => {
    new Promise(r => setTimeout(r)).then(() => {
      setShow(true)
    })
  }, [])
  return (
    <div
      style={{
        display: 'flex',
        minHeight: 'calc(3vw)',
        backgroundImage: `url(${imgPlaceHolderData})`,
      }}
    >
      <div
        style={{ flex: 1, transitionDelay: `900ms` }}
        className={show ? styles.fullOpacity : styles.hiddenOpacity}
      >
        {show ? children : null}
      </div>
    </div>
  )
}
export default AdItem
