import React from 'react'
import * as styles from './weather-ui.module.css'
import { cn } from './../lib/helpers'
import { initPlaces } from 'algoliasearch'

export default props => {
  const { weatherMin, weatherMax, weatherCurr, description, icon } = props
  // console.log(weatherCurr, weatherMax, weatherMin)

  if (weatherCurr && weatherMax && weatherMin) {
    const weather = {
      min: Math.round(weatherMin),
      max: Math.round(weatherMax),
      curr: Math.round(weatherCurr),
    }
    return (
      <div className={styles.wrapper}>
        <h2 className={styles.title}>الطقس الآن</h2>
        <InitiallyHiddenShort>
          <h2 className={styles.subTitle}>SA ,القطيف</h2>
          {icon && (
            <img
              className="m-auto"
              alt={status}
              srcSet={`./../../images/weather/${icon}.png, ./../../images/weather/${icon}@2x.png 2x`}
              src={`./../../images/weather/${icon}.png`}
            />
          )}
          <div className={styles.weatherTmp}>
            <p className={styles.weatherTmpCurr}>{weather.curr}&deg;C</p>
            <p className={styles.weatherTmpMinMax}>
              {weather.min}&deg;C / {weather.max}&deg;C
            </p>
            <p>{description}</p>
          </div>
        </InitiallyHiddenShort>
      </div>
    )
  }
  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>الطقس الآن</h2>

      <InitiallyHiddenLong>
        {props.error && (
          <div
            style={{
              backgroundColor: 'rgb(255,122,122)',
              fontWeight: 700,
              color: 'white',
              padding: '.4em .8em',
            }}
          >
            {' '}
            حدث خطأ
          </div>
        )}
      </InitiallyHiddenLong>
    </div>
  )
}

// for transition fade-in fade-out with long period
const InitiallyHiddenLong = ({ children }) => {
  const [show, setShow] = React.useState(false)

  React.useEffect(() => {
    new Promise(r => setTimeout(r)).then(() => {
      setShow(true)
    })
  }, [])
  return (
    <div
      style={{ transitionDelay: `3500ms` }}
      className={show ? styles.fullOpacity : styles.hiddenOpacity}
    >
      {children}
    </div>
  )
}
// for transition fade-in fade-out with short period
const InitiallyHiddenShort = ({ children }) => {
  const [show, setShow] = React.useState(false)

  React.useEffect(() => {
    new Promise(r => setTimeout(r)).then(() => {
      setShow(true)
    })
  }, [])
  return (
    <div
      style={{ transitionDelay: `100ms` }}
      className={show ? styles.fullOpacity : styles.hiddenOpacity}
    >
      {children}
    </div>
  )
}
