import React from 'react'
import calendarize from 'calendarize'
import { isWithinInterval, differenceInCalendarDays } from 'date-fns'
import CalendarUI from './calendarUI'

const Hijri_1446 = {
  محرم: { start: [2024, 7, 8], end: [2024, 8, 5] },
  صفر: { start: [2024, 8, 6], end: [2024, 9, 4] },
  'ربيع الأول': { start: [2024, 9, 5], end: [2024, 10, 4] },
  'ربيع الآخر': { start: [2024, 10, 5], end: [2024, 11, 3] },
  'جمادى الأول': { start: [2024, 11, 4], end: [2024, 12, 2] },
  'جمادى الآخر': { start: [2024, 12, 3], end: [2025, 1, 1] },
  رجب: { start: [2025, 1, 2], end: [2025, 1, 30] },
  شعبان: { start: [2025, 1, 31], end: [2025, 3, 1] },
  رمضان: { start: [2025, 3, 2], end: [2025, 3, 30] },
  شوال: { start: [2025, 3, 31], end: [2025, 4, 28] },
  'ذو القعدة': { start: [2025, 4, 29], end: [2025, 5, 28] },
  'ذو الحجة': { start: [2025, 5, 29], end: [2025, 6, 26] },
}
const Hijri_1445 = {
  محرم: { start: [2023, 7, 20], end: [2023, 8, 17] },
  صفر: { start: [2023, 8, 18], end: [2023, 9, 16] },
  'ربيع الأول': { start: [2023, 9, 17], end: [2023, 10, 16] },
  'ربيع الآخر': { start: [2023, 10, 17], end: [2023, 11, 15] },
  'جمادى الأول': { start: [2023, 11, 16], end: [2023, 12, 14] },
  'جمادى الآخر': { start: [2023, 12, 15], end: [2024, 1, 12] },
  رجب: { start: [2024, 1, 13], end: [2024, 2, 11] },
  شعبان: { start: [2024, 2, 12], end: [2024, 3, 11] },
  رمضان: { start: [2024, 3, 12], end: [2024, 4, 9] },
  شوال: { start: [2024, 4, 10], end: [2024, 5, 9] },
  'ذو القعدة': { start: [2024, 5, 10], end: [2024, 6, 7] },
  'ذو الحجة': { start: [2024, 6, 8], end: [2025, 7, 7] },
}
const Hijri_1444 = {
  محرم: { start: [2022, 7, 30], end: [2022, 8, 28] },
  صفر: { start: [2022, 8, 29], end: [2022, 9, 27] },
  'ربيع الأول': { start: [2022, 9, 28], end: [2022, 10, 26] },
  'ربيع الآخر': { start: [2022, 10, 27], end: [2022, 11, 25] },
  'جمادى الأول': { start: [2022, 11, 26], end: [2022, 12, 24] },
  'جمادى الآخر': { start: [2022, 12, 25], end: [2023, 1, 22] },
  رجب: { start: [2023, 1, 23], end: [2023, 2, 21] },
  شعبان: { start: [2023, 2, 22], end: [2023, 3, 22] },
  رمضان: { start: [2023, 3, 23], end: [2023, 4, 21] },
  شوال: { start: [2023, 4, 22], end: [2023, 5, 20] },
  'ذو القعدة': { start: [2023, 5, 21], end: [2023, 6, 19] },
  'ذو الحجة': { start: [2023, 6, 20], end: [2023, 7, 19] },
}
const Hijri_1443 = {
  محرم: { start: [2021, 8, 10], end: [2021, 9, 8] },
  صفر: { start: [2021, 9, 9], end: [2021, 10, 7] },
  'ربيع الأول': { start: [2021, 10, 8], end: [2021, 11, 6] },
  'ربيع الآخر': { start: [2021, 11, 7], end: [2021, 12, 5] },
  'جمادى الأول': { start: [2021, 12, 6], end: [2022, 1, 3] },
  'جمادى الآخر': { start: [2022, 1, 4], end: [2022, 2, 2] },
  رجب: { start: [2022, 2, 3], end: [2022, 3, 3] },
  شعبان: { start: [2022, 3, 4], end: [2022, 4, 2] },
  رمضان: { start: [2022, 4, 3], end: [2022, 5, 2] },
  شوال: { start: [2022, 5, 3], end: [2022, 5, 31] },
  'ذو القعدة': { start: [2022, 6, 1], end: [2022, 6, 30] },
  'ذو الحجة': { start: [2022, 7, 1], end: [2022, 7, 29] },
}
const Hijri_1442 = {
  محرم: { start: [2020, 8, 21], end: [2020, 9, 18] },
  صفر: { start: [2020, 9, 19], end: [2020, 10, 17] },
  'ربيع الأول': { start: [2020, 10, 18], end: [2020, 11, 16] },
  'ربيع الآخر': { start: [2020, 11, 17], end: [2020, 12, 15] },
  'جمادى الأول': { start: [2020, 12, 16], end: [2021, 1, 14] },
  'جمادى الآخر': { start: [2021, 1, 15], end: [2021, 2, 13] },
  رجب: { start: [2021, 2, 14], end: [2021, 3, 14] },
  شعبان: { start: [2021, 3, 15], end: [2021, 4, 13] },
  رمضان: { start: [2021, 4, 14], end: [2021, 5, 13] },
  شوال: { start: [2021, 5, 14], end: [2021, 6, 11] },
  'ذو القعدة': { start: [2021, 6, 12], end: [2021, 7, 11] },
  'ذو الحجة': { start: [2021, 7, 12], end: [2021, 8, 9] },
}
const Hijri_1441 = {
  محرم: { start: [2019, 9, 1], end: [2019, 9, 29] },
  صفر: { start: [2019, 9, 30], end: [2019, 10, 29] },
  'ربيع الأول': { start: [2019, 10, 30], end: [2019, 11, 27] },
  'ربيع الآخر': { start: [2019, 11, 28], end: [2019, 12, 27] },
  'جمادى الأول': { start: [2019, 12, 28], end: [2020, 1, 26] },
  'جمادى الآخر': { start: [2020, 1, 27], end: [2020, 2, 24] },
  رجب: { start: [2020, 2, 25], end: [2020, 3, 25] },
  شعبان: { start: [2020, 3, 26], end: [2020, 4, 24] },
  رمضان: { start: [2020, 4, 25], end: [2020, 5, 23] },
  شوال: { start: [2020, 5, 24], end: [2020, 6, 22] },
  'ذو القعدة': { start: [2020, 6, 23], end: [2020, 7, 21] },
  'ذو الحجة': { start: [2020, 7, 22], end: [2020, 8, 20] },
}

const hijriOffset = dayInMonth => (dayInMonth - 1) * -1

const dateFrom = dateAsArr => {
  if (!Array.isArray(dateAsArr)) throw new Error('expected array of correct date')
  const dateFromated = dateAsArr
    .map(dayMonth => (dayMonth.toString().length === 1 ? `0${dayMonth}` : dayMonth))
    .join('-')
  const normnalized = new Date(dateFromated).toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  })
  return new Date(normnalized)
}

const currentHijriMonth = () => {
  const allHijri = Object.entries(Hijri_1446)
  const oldHijri = Object.entries(Hijri_1445)

  let currHijri = allHijri
    .filter(([key, hijriMonth]) => {
      const start = dateFrom(hijriMonth.start)
      const end = dateFrom(hijriMonth.end)
      const todayDate = new Date().toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
      })
      return isWithinInterval(new Date(todayDate), { start, end })
    })
    .pop()
  if (!currHijri) {
    currHijri = oldHijri
      .filter(([key, hijriMonth]) => {
        const start = dateFrom(hijriMonth.start)
        const end = dateFrom(hijriMonth.end)
        const todayDate = new Date().toLocaleDateString('en-US', {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric',
        })
        return isWithinInterval(new Date(todayDate), { start, end })
      })
      .pop()
  }
  if (!currHijri) return []
  return currHijri
}

const [currentMonthAr] = currentHijriMonth()
const daysInMonth = () => {
  const [, currHijriDate] = currentHijriMonth()
  return (
    differenceInCalendarDays(dateFrom(currHijriDate.end), dateFrom(currHijriDate.start)) + 1
  )
}
const currentMonthHijriView = () => {
  const [, currHijriDate] = currentHijriMonth()
  return hijriView(currHijriDate.start, currHijriDate.end)
}

const hijriView = (startD, endD) => {
  const [, , day] = startD
  const offset = hijriOffset(day)
  return calendarize(dateFrom(startD), offset)
}

const currentHijriDay = () => {
  const [, hijriMonth] = currentHijriMonth()
  const beginOfHijriMonth = dateFrom(hijriMonth.start)
  const currDayInHijri = differenceInCalendarDays(new Date(), beginOfHijriMonth) + 1
  return currDayInHijri
}

const Calendar = props => {
  const { hideDownload = false } = props
  return (
    <div>
      <CalendarUI
        hideDownload={hideDownload}
        hijriView={currentMonthHijriView()}
        hijriDay={currentHijriDay()}
        month_ar={currentMonthAr}
        daysInMonth={daysInMonth()}
      />
    </div>
  )
}

export default Calendar
