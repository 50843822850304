import { Link } from 'gatsby'
import React from 'react'
import { LiteYouTubeEmbed } from 'react-lite-youtube-embed'
import BlogPostPreview from './blog-post-preview'
import { cn } from '../lib/helpers'
import * as styles from './blog-post-preview-list.module.css'
import { inList, leadMediaThumb, text, title, date } from './blog-post-preview.module.css'
import {
  responsiveTitle3,
  responsiveTitle1,
  smallCustom,
  small,
  microCustom,
} from './typography.module.css'

function VideoPreviewList(props) {
  const smallText = props.small
  const { slug } = props
  return (
    <div className={styles.root}>
      {/* {props.title && <h2 className={styles.headline}>{props.title}</h2>} */}
      {props.title && (
        <div className={styles.headline}>
          <h2>{props.title}</h2>
          {slug && (
            <a href={slug} className={styles.displayAll}>
              يوتوب الجمعية &rsaquo;
            </a>
          )}
        </div>
      )}

      <ul className={styles.gridAsList}>
        {Array.isArray(props.nodes) &&
          props.nodes.map(node => (
            <li key={node.id}>
              <div className={inList}>
                <LiteYouTubeEmbed id={node.videoId} title={node.title} />
                <div className={text}>
                  <h3 className={cn(smallText ? smallCustom : responsiveTitle3, title)}>
                    {node.title}
                  </h3>
                  <div className={cn(smallText ? microCustom : small, date)}>
                    <time dateTime={new Date(node.publishedAt).toLocaleDateString('en-US')}>
                      {new Date(node.publishedAt).toLocaleDateString('ar-SA', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                      })}
                    </time>
                  </div>
                </div>
              </div>
            </li>
          ))}
      </ul>
    </div>
  )
}

function BlogPostPreviewList(props) {
  const { slug } = props
  return (
    <div className={styles.root}>
      {props.title && (
        <div className={slug ? styles.headline : styles.recentPost}>
          <Link to={slug || '/'}>
            <h2>{props.title}</h2>
          </Link>
          {slug && (
            <Link to={slug} className={styles.displayAll}>
              عرض الكل &rsaquo;
            </Link>
          )}
        </div>
      )}
      <ul data-size={props.small ? 'small' : 'normal'} className={styles.gridAsList}>
        {Array.isArray(props.nodes) &&
          props.nodes.map(node => (
            <li key={node.id}>
              <BlogPostPreview {...node} small={props.small} isInList />
            </li>
          ))}
      </ul>
    </div>
  )
}

function BlogPostPreviewGrid(props) {
  return (
    <div className={styles.root}>
      <ul className={styles.grid}>
        {Array.isArray(props.nodes) &&
          props.nodes.map(node => (
            <li key={node.id}>
              <BlogPostPreview showExcerpt={props.showExcerpt} {...node} />
            </li>
          ))}
      </ul>
    </div>
  )
}
BlogPostPreviewGrid.defaultProps = {
  title: '',
  nodes: [],
}

export { BlogPostPreviewGrid, BlogPostPreviewList, VideoPreviewList }
