import React from 'react'
import { Link } from 'gatsby'

import * as styles from './aside-layout.module.css'
import Weather from './weather'
import Icon from './icon'
import Calendar from '../components/pages/calendar'

function AsideLayout({ items, title }) {
  // React.useEffect(() => console.log({ inView }))

  return (
    <aside className={styles.aside}>
      <span style={{ visibility: 'hidden' }}>side content</span>
      <Weather />
      <div className={styles.sperator}> </div>
      <p>
        <Link className={styles.sideNavButton} to="/membership">
          <div className={styles.svgFill}>
            <Icon symbol="joinUs" />
          </div>
          <h3>العضوية</h3>
        </Link>
      </p>
      <p>
        <Link className={styles.sideNavButtonSmall} to="/management">
          <div className={styles.svgFill}>
            <Icon symbol="team" />
          </div>
          <h3>الإدارة</h3>
        </Link>
      </p>
      <div className={styles.sperator}> </div>
      <Calendar hideDownload={true} />
      <span style={{ fontSize: '.75rem' }}>تقويم الجمعية</span>
      <div className={styles.sperator}> </div>
      <p>إحداثي مرصد الجمعية (26:33:44 شمال، 49:56:26شرق)</p>
      <p>
        <div>التوقيت المحلي:+3</div>
      </p>
      <p style={{ margin: '1.4rem 0 0 0' }}>
        <Icon symbol="mapPin" fill="gray" />
      </p>
      <a
        target="_blank"
        rel="noreferrer"
        style={{ color: '#4a4e4f' }}
        href="https://goo.gl/maps/2ENAYipzo6HLdBCA9"
      >
        {' '}
        إضغط هنا لمشاهدة موقع الجمعية{' '}
      </a>
      <hr />
      <hr />
    </aside>
  )
}

export default AsideLayout
