// extracted by mini-css-extract-plugin
export var aside = "aside-layout-module--aside--62e05";
export var base = "aside-layout-module--base--d1b2d";
export var blockQuote = "aside-layout-module--blockQuote--c5978";
export var large = "aside-layout-module--large--062e3";
export var micro = "aside-layout-module--micro--54ce8";
export var microCustom = "aside-layout-module--microCustom--09759";
export var paragraph = "aside-layout-module--paragraph--32ab2";
export var responsiveTitle1 = "aside-layout-module--responsiveTitle1--d92dc";
export var responsiveTitle2 = "aside-layout-module--responsiveTitle2--9b5a2";
export var responsiveTitle3 = "aside-layout-module--responsiveTitle3--778ff";
export var responsiveTitle4 = "aside-layout-module--responsiveTitle4--3cc8c";
export var root = "aside-layout-module--root--90c90";
export var sideNavButton = "aside-layout-module--sideNavButton--32e91";
export var sideNavButtonSmall = "aside-layout-module--sideNavButtonSmall--6b0a7";
export var small = "aside-layout-module--small--812db";
export var smallCustom = "aside-layout-module--smallCustom--e08e7";
export var sperator = "aside-layout-module--sperator--1b9ca";
export var svgFill = "aside-layout-module--svgFill--f6654";
export var title1 = "aside-layout-module--title1--7e9be";
export var title2 = "aside-layout-module--title2--e6c9c";
export var title3 = "aside-layout-module--title3--66b45";