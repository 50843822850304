import React from 'react'
import { Link, graphql } from 'gatsby'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
} from '../lib/helpers'
import GraphQLErrorList from '../components/graphql-error-list'
import Vote from '../components/siteLaunchVote'
import SEO from '../components/seo'
import Container from '../components/container'
import Layout from '../containers/layout'
import MainContent from '../containers/main-content.js'
import {
  BlogPostPreviewList,
  BlogPostPreviewGrid,
  VideoPreviewList,
} from '../components/blog-post-preview-list'
import AsideLayout from '../components/aside-layout'
import BlogPostPreview from './../components/blog-post-preview'
import AdItem from './../components/adItem'
import { LiteYouTubeEmbed } from 'react-lite-youtube-embed'
import { micro, responsiveTitle3 } from './../components/typography.module.css'
import { date } from '../components/blog-post-preview.module.css'
import { cn } from '../lib/helpers'
const { NewsGrid, VideosGrid } = MainContent
export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    postsForArticles: allSanityPost(
      limit: 7
      sort: { fields: [publishedAt], order: DESC }
      filter: {
        category: { name: { regex: "/articles/i" } }
        slug: { current: { ne: null } }
        publishedAt: { ne: null }
      }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          category {
            name
            name_ar
          }
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
    postsForNews: allSanityPost(
      limit: 7
      sort: { fields: [publishedAt], order: DESC }
      filter: {
        category: { name: { regex: "/news|astronomy_events|occasions/i" } }
        slug: { current: { ne: null } }
        publishedAt: { ne: null }
      }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          category {
            name
            name_ar
          }
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
    postsForActivities: allSanityPost(
      limit: 7
      sort: { fields: [publishedAt], order: DESC }
      filter: {
        category: { name: { regex: "/activities/i" } }
        slug: { current: { ne: null } }
        publishedAt: { ne: null }
      }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          category {
            name
            name_ar
          }
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
    posts: allSanityPost(
      limit: 5
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          category {
            name
            name_ar
          }
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
    videos: allYoutubeVideo(limit: 4) {
      edges {
        node {
          id
          title
          description
          videoId
          publishedAt
        }
      }
    }
  }
`

const IndexPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : []
  const articlesPostNodes = (data || {}).postsForArticles
    ? mapEdgesToNodes(data.postsForArticles)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
        .filter(node => {
          return !postNodes.map(p => p.id).includes(node.id) //?
        })
        .filter((_, ind) => ind < 2)
    : []
  const newsPostNodes = (data || {}).postsForNews
    ? mapEdgesToNodes(data.postsForNews)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
        .filter(node => {
          return !postNodes.map(p => p.id).includes(node.id) //?
        })
        .filter((_, ind) => ind < 3)
    : []

  const activitiesPostNodes = (data || {}).postsForActivities
    ? mapEdgesToNodes(data.postsForActivities)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
        .filter(node => {
          return !postNodes.map(p => p.id).includes(node.id) //?
        })
        .filter((_, ind) => ind < 3)
    : []

  const [firstVideo, ...videos] = (data || {}).videos ? mapEdgesToNodes(data.videos) : []

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.',
    )
  }

  return (
    <Layout>
      <Container>
        {/* <Search /> */}
        <NewsGrid>
          {/* <BlogPostPreviewGrid nodes={postNodes.slice(0, 1)} title="أحدث المواضيع" /> */}
          <div style={{ position: 'relative' }}>
            <span style={{ visibility: 'hidden' }}>featrued</span>
            <BlogPostPreview {...postNodes[0]} />
          </div>
          <BlogPostPreviewList small nodes={postNodes.slice(1, 5)} title={'أحدث المواضيع'} />
        </NewsGrid>
        <NewsGrid>
          <div>
            <span style={{ visibility: 'hidden' }}>ads</span>
            <AdItem />
            <BlogPostPreviewList
              nodes={activitiesPostNodes}
              slug="/anshth-w-faalyat"
              title={'الأنشطة'}
            />
            <BlogPostPreviewList nodes={newsPostNodes} slug="/akhbar-falak" title={'الفلك'} />
            <BlogPostPreviewList
              nodes={articlesPostNodes}
              slug="/articles"
              title={'المقالات'}
            />
          </div>
          <section>
            <hr />
            <hr />
            <AsideLayout />
            <Vote />
          </section>
        </NewsGrid>
        <VideosGrid>
          <div data-position="first">
            <h2 style={{ visibility: 'hidden' }}>الفيدو الأحدث</h2>
            {firstVideo && (
              <>
                <LiteYouTubeEmbed id={firstVideo.videoId} title={firstVideo.title} />
                <h2 className={responsiveTitle3}>{firstVideo.title}</h2>
                <div className={cn(micro, date)} style={{ background: 'unset' }}>
                  {new Date(firstVideo.publishedAt).toLocaleDateString('ar-SA', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                  })}
                </div>
                <hr />
                <hr />
                <hr />
              </>
            )}
          </div>
          {videos && (
            <VideoPreviewList
              firstVideo={firstVideo}
              title="فيدوات"
              slug="https://www.youtube.com/channel/UCxBDO83HiA4gF-32EVmDLQw/videos"
              small
              nodes={videos}
            />
          )}
        </VideosGrid>
      </Container>
    </Layout>
  )
}

export default IndexPage
export const Head = () => <SEO />
