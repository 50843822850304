// extracted by mini-css-extract-plugin
export var date = "blog-post-preview-module--date--8249f";
export var excerpt = "blog-post-preview-module--excerpt--069b5";
export var inGrid = "blog-post-preview-module--inGrid--4aed1 blog-post-preview-module--root--9593a";
export var inList = "blog-post-preview-module--inList--574ff blog-post-preview-module--root--9593a";
export var inlineTags = "blog-post-preview-module--inlineTags--e5a22";
export var leadMediaThumb = "blog-post-preview-module--leadMediaThumb--36e45";
export var root = "blog-post-preview-module--root--9593a";
export var text = "blog-post-preview-module--text--fe8c5";
export var textNoDecoration = "blog-post-preview-module--textNoDecoration--0e8ed";
export var thumbWrapper = "blog-post-preview-module--thumbWrapper--16347";
export var title = "blog-post-preview-module--title--41496";